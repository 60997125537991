import React, { Component } from "react";

import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
// import Iframe from "react-iframe";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Footer } from "../share/footer";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { FxService } from "../../service/fx.service";
import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import { TopBar } from "../share/topBar";
import { BetBtn } from "../share/bet-btn";
import { MiniService } from "../../service/mini.service";
import { colors } from "@material-ui/core";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: "#424242",
  },
  paper: {
    paddingTop: 100,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomCardContent = styled(CardContent)`
  padding: 0px;
`;

const CustomButton = styled(Button)`
  width: 29%;
  font-size: 11px;
  margin: 4px;
`;

// const classes = useStyles();

interface Props {
  classes: any;
  user: any;
}

interface State {
  pass1: any;
  pass2: any;
}

class rolling extends Component<Props, State> {
  divElement: any;
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      pass1: "",
      pass2: "",
    };
  }

  componentDidMount() {}

  updateUserDate = () => {
    // this.userService.getUserInfo().then((ss) => {
    //   if (ss.status === "success") {
    //     this.setState({ user: ss.user });
    //   }
    // });
  };

  

  handleExchangeToPoint = () => {
    this.userService.rolingToBalance(this.props?.user?.rolling).then((data : any) => {
      if (data.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "롤링포인트가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload()
              },
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "회원정보",
          message: "1000포인트 이상부터 사용가능합니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {

    console.log(this.props.user)
    return (
      <React.Fragment>
        {/* <div className="header_wrap">
          <TopBar user={this.props.user}></TopBar>
        </div> */}

        <div className="contents_wrap">
          <div className="contents_box">
            <div className="con_box00">
              <div className="m_tab_wrap">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        window.location.hash = "mypage";
                      }}
                    >
                      <span className="m_tab">회원정보</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.hash = "rolling";
                      }}
                    >
                      <span className="m_tabon">롤링</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.hash = "depositandwithdraw";
                      }}
                    >
                      <span className="m_tab">입금출 기록</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.hash = "note";
                      }}
                    >
                      <span className="m_tab">쪽지함</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="con_box05">
              <table className="write_title_top">
                <tr>
                  <td className="write_title">보유 롤링</td>
                  <td className="write_basic">
                  <input
                      className="input1"
                      value={this.props.user?.rolling}
                      readOnly
                    />
                  </td>
                </tr> 
  
              
              </table>
            </div>
            <div className="con_box05">
              <div className="btn_wrap_center">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        this.handleExchangeToPoint();
                      }}
                    >
                      <span className="btn3_1">변경</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <CssBaseline />

        <Footer />
      </React.Fragment>
    );
  }
}

rolling.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Rolling = withStyles(styles, { withTheme: true })(rolling);
export default Rolling;
