import React, { Component } from "react";

import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
// import Iframe from "react-iframe";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { Footer } from "../share/footer";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { FxService } from "../../service/fx.service";
import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import { TopBar } from "../share/topBar";
import { BetBtn } from "../share/bet-btn";
import { MiniService } from "../../service/mini.service";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: "#424242",
  },
  paper: {
    paddingTop: 100,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomButton = styled(Button)`
  width: 29%;
  font-size: 11px;
  margin: 4px;
`;

// const classes = useStyles();

interface Props {
  classes: any;
  session: any;
  user: any;
}

interface State {
  user: any;
  minis: any;
  withdraws: any;
  history: any;
  endDate: any;
  minute: number;
  maxCount: number;
  page: number;
  balance: number;
  name: string;
  pass: string;
}

class withdraw extends Component<Props, State> {
  divElement: any;
  static propTypes: { classes: PropTypes.Validator<object> };
  miniService = new MiniService();
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      pass: "",

      minis: null,
      history: [],
      withdraws: [],
      minute: 1,
      maxCount: 1,
      endDate: "",
      page: 1,
      balance: 0,
      user: {
        balance: 0,
      },
    };
  }

  componentDidMount() {
    this.miniService.getMiniGameByGameType("pwball").then((s: any) => {
      if (s.status === "success") {
        this.setState({ minis: s.minis[0] });
      }
    });

    this.balanceService.getWithdrawList().then((data: any) => {
      if (data.status === "success") {
        this.setState({ withdraws: data.deposits });
      }
    });
  }

  createData = (
    name: any,
    balnce: any,
    fat: any,
    maxBalanceCount: any,
    carbs: any,
    protein: any
  ) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    };
  };

  updateUserDate = () => {
    // this.userService.getUserInfo().then((ss) => {
    //   if (ss.status === "success") {
    //     this.setState({ user: ss.user });
    //   }
    // });
  };

  handleDoWithdraw = () => {
    let balance = this.state.balance;
    if (balance <= 0) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    confirmAlert({
      title: "출금",
      message: "출금신청을 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .applyUserWithdrawV3(this.state.pass, balance)
              .then((data) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "출금",
                    message: "출금 신청이 완료되습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "pass") {
                  confirmAlert({
                    title: "출금",
                    message: "환전 비빌번호를 확인해주세요.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                  return;
                } else if (data.status === "wait") {
                  confirmAlert({
                    title: "출금",
                    message: "현제 대기중인 출금 신청이 있습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "예러...",
                    message:
                      "예러가 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
    return;
  };

  RenderDepositTable = (rows: any) => {
    return (
      <Grid container spacing={1}>
        {/* <Grid item xs={2}></Grid> */}

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#484848" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">출금 금액</TableCell>
                  <TableCell align="right">출금 일</TableCell>
                  <TableCell align="right">상태</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell align="right">
                      {ConverMoeny(row.balance)}
                    </TableCell>
                    <TableCell align="right">
                      {ConvertDate(row.regdate)}
                    </TableCell>
                    <TableCell align="right">
                      {ConvertBalanceStateToText(row.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  render() {
    const classes = this.props.classes;
    const withdraws = this.state.withdraws;

    return (
      <React.Fragment>
        {/* <div className="header_wrap">
          <TopBar user={this.props.user}></TopBar>
        </div> */}

        <div className="contents_wrap">
          <div className="contents_box">
            <div className="con_box05">
              <table className="write_title_top" style={{ width: "100%" }}>
                <tr>
                  <td className="write_title">출금금액</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.balance}
                      onChange={(e: any) => {
                        this.setState({ balance: Number(e.target.value) });
                      }}
                    />
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 30000 });
                            }}
                          >
                            <span className="m_btn1">3만원</span>
                          </a>
                        </td>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 50000 });
                            }}
                          >
                            <span className="m_btn1">5만원</span>
                          </a>
                        </td>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 100000 });
                            }}
                          >
                            <span className="m_btn1">10만원</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 300000 });
                            }}
                          >
                            <span className="m_btn1">30만원</span>
                          </a>
                        </td>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 500000 });
                            }}
                          >
                            <span className="m_btn1">50만원</span>
                          </a>
                        </td>
                        <td align="center">
                          <a
                            onClick={() => {
                              this.setState({ balance: 0 });
                            }}
                          >
                            <span className="m_btn1">Clear</span>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td className="write_title">환전비번</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.pass}
                      onChange={(e) =>
                        this.setState({
                          pass: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td className="write_title">은행</td>
                  <td className="write_basic"></td>
                </tr>

                <tr>
                  <td className="write_title">출금자</td>
                  <td className="write_basic"></td>
                </tr>
                <tr>
                  <td className="write_title">계좌번호</td>
                  <td className="write_basic"></td>
                </tr>
              </table>
            </div>
            {/* <div className="con_box05">
              <div className="info_wrap">
                <div className="info3">
                  <span className="font05">
                    출금은 3만원 이상부터 가능하며, 부분 출금이 되지 않습니다.
                  </span>{" "}
                  출금시 천원단위까지 전액 출금부탁드립니다.
                  <br />
                  입금자명과 출금자명이 다를경우 본인확인 요청이 있을 수
                  있습니다.
                </div>
              </div>
            </div> */}
            <div className="con_box10">
              <div className="btn_wrap_center">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        this.handleDoWithdraw();
                      }}
                    >
                      <span className="btn3_1">출금신청</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <CssBaseline />

        <Footer />
      </React.Fragment>
    );
  }
}

withdraw.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Withdraw = withStyles(styles, { withTheme: true })(withdraw);
export default Withdraw;
